/************************************ this file include all pages style ******************************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Assistant" !important;
  text-decoration: none !important;
}

/* accent colors */
:root {
  --primary-color: #006aff;
  --secondary-color: #dfe1eb;
  --white: white;
  --default-bg-color: #f8f8f8;
  --default-color: black;
  --success-color: #2e7d32;
  --blue-color: #21b4e2;
  --golden-color: #f1d600;
  --gray-color: gray;
  --red-color: red;
  --orange-color: orange;
  --glass-color: rgba(255, 255, 255, 0.55);
  --glass-border: rgba(209, 213, 219, 0.3);
  --transperent-black-color: rgba(20, 20, 20, 0.8);
  --error-color: #d32f2f;
  --header-color: rgba(255, 255, 255, 0.1);
  --label-color: rgba(0, 0, 0, 0.6);
  --gradient-color: linear-gradient(
    to right,
    #b7deed 0%,
    #07004d 25%,
    #21b4e2 75%,
    #b7deed 100%
  );
  --darkBlue-color: #07004d;
}

body {
  background-color: var(--glass-color);
}

/*added because of material-table*/
.sidebar-section .MuiButtonBase-root,
.primary-button,
.reset-button,
.success-button {
  padding: 5px 15px !important;
}

.primary-button {
  background: var(--blue-color) !important;
  border: 1px solid var(--blue-color) !important;
  color: var(--white) !important;
  border-radius: 12px !important;
}

.primary-button:hover {
  background: transparent !important;
  color: var(--blue-color) !important;
}

.forgot-button {
  background: var(--white) !important;
  border: 1px solid var(--blue-color) !important;
  color: var(--blue-color) !important;
  border-radius: 12px !important;
}

.success-button {
  background: var(--success-color) !important;
  border: 1px solid var(--success-color) !important;
  color: var(--white) !important;
  border-radius: 12px !important;
}

.success-button:hover {
  background: transparent !important;
  color: var(--success-color) !important;
}

.bg-gradient {
  background: var(--darkBlue-color) !important;
}

/******************************** Loading modal style ********************************************/
.loading-modal .MuiDialogContent-root {
  border: none !important;
}

.loading-modal .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.loading-modal .css-uhb5lp {
  background: none !important;
  box-shadow: none !important;
}

/****************************** login page style **************************/

.login-section .bg-gradient {
  clip-path: polygon(0% 0%, 100% 0%, 70% 100%, 0% 100%);
}

.login-section img {
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
}

/***************************** Header page style *******************************************/
.header-section {
  background: var(--darkBlue-color) !important;
  width: 100%;
  z-index: 100;
  padding: 10px 10px;
  padding-left: 16px;
  padding-right: 16px;
}

/********************************  side bar page ****************************************************/
.sidebar-section {
  position: relative !important;
  width: 220px;
  overflow: auto;
  height: 100vh;
  margin-top: -17px;
}

/* sidebar  icons */
.sidebar-section .css-cveggr-MuiListItemIcon-root,
.sidebar-section .css-1f8bwsm {
  min-width: 35px !important;
}

/* sidebar svg hover function */
.sidebar-section .MuiButtonBase-root:hover svg {
  border-bottom: 3px solid;
  border-image: var(--gradient-color) 1;
}

.sidebar-section .MuiButtonBase-root:hover {
  box-shadow: var(--bs-box-shadow) !important;
  background: transparent !important;
}

.sidebar-section .MuiButtonBase-root:hover .no-hover {
  border: none !important;
}

/* collapse items  */
.sidebar-section .MuiCollapse-wrapperInner {
  margin-left: 30px;
}
