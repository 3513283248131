/************************************* this file includes  only responsive code *******************************/

/************************* Custom, iPhone Retina *************************/
@media screen and (min-width: 320px) and (max-width: 767px) {
    .login-section .bg-gradient {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }

    .login-section .card-body {
        padding: 1.25rem !important;

    }


    .login-section .card {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .login-section .card .login-form {
        padding: 0 !important;
    }

}